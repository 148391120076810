@import url('https://fonts.googleapis.com/css2?family=Titan+One&display=swap');
.cube {
    position: relative;
    overflow: hidden;
    text-align: center;
    padding: 20% 0;
    font-size: 5vw;
    font-family: 'Titan One', cursive;
    width: 100%;
    height: 100%;
    background-color: #F5F5F5;
    border-radius: 15%;
  }
  
  .cube > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%; /* Adjust the size of the circle */
    height: 90%; /* Adjust the size of the circle */
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Titan One', cursive; /* Apply Titan One font family here */
  }
  
  .cube span {
    color: blue;
    font-family: 'Titan One', cursive; /* Apply Titan One font family here */
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 5 columns */
    gap: 1px; /* Gap between items */
    justify-items: center; /* Align items horizontally */
    align-items: center; /* Align items vertically */
    margin: 0 auto; /* Center the grid */
    aspect-ratio: 1 / 1;
    max-width: 90%;
    max-height: 90%;
    width: min(90vw, 90vh);
  }


  .boggle-button {
    grid-column: span 2;
    background-color: #FFC107;
    color: white;
    padding: 1px 1px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 3vh;
    font-family: 'Titan One', cursive;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
  }

  .timer {
    background-color: white;
    color: black;
    padding: 1px 1px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 3vh;
    height: 50%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
  }
  .boggle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #0dcaf0;
  }