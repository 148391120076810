/* LandingPage.css */

.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f4f4f4; /* Updated background color */
  color: #333; /* Updated text color */
  text-align: center;
  padding: 20px; /* Added padding for better spacing */
}

.title {
  font-size: 3rem;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  margin-bottom: 40px;
}

.options {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* Added gap between option items */
}

.option p {
  font-size: 1.25rem;
}

.option:hover {
  transform: scale(1.05);
  transition: transform 0.2s ease-in-out;
  background-color: #fff; /* Added background color on hover */
}

.themes-dropdown {
  margin-top: 20px; /* Added margin for separation */
}

.players-field {
  margin-top: 20px; /* Added margin for separation */
}

.players-list {
  max-height: 150px; /* Added max-height for scrollable list */
  border: 1px solid #ccc; /* Added border for list container */
  border-radius: 5px; /* Added border radius for rounded edges */
  padding: 10px; /* Added padding for spacing within the list */
}

.option {
  position: relative; /* Add relative positioning to the option */
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.option i {
  font-size: 3rem;
  margin-bottom: 10px;
}

.checkmark {
  position: absolute; /* Position checkmark relative to the option */
  top: 10px;
  right: 10px;
  color: #28a745;
  font-size: 1.5rem;
}


.btn-primary {
  background-color: #007bff; /* Updated primary button color */
}

.btn-success {
  background-color: #28a745; /* Updated success button color */
}
