/* GameSelection.css */

.game-selection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .game-options {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .game-option {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .game-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .game-description {
    color: #777;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .play-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .play-button:hover {
    background-color: #0056b3;
  }
  